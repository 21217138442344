import React, {useContext} from "react";
import {
    GlobalDispatchContext,
    GlobalStateContext,
  } from "../../stores/GlobalContextProvider";
const Header = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const globalState = useContext(GlobalStateContext);

    // let { globalState, dispatch } = React.useContext(GlobalStateContext);
    return (
        <>
            <header id="head">
                <h1>
                    STUDIO M NEDVĚD{" "}
                    <button
                        onClick={() => {
                            if (globalState.view === "index") {
                                dispatch({ type: "SET_VIEW", payload: "full" });
                                localStorage.setItem("view", "full");
                            } else {
                                dispatch({ type: "SET_VIEW", payload: "index" });
                                localStorage.setItem("view", "index");
                            }
                        }}
                        // style={{ textTransform: "uppercase" }}
                    >
                        (VIEW WORKS AS <span>{globalState.view === "full" ? "INDEX" : "FULL VIEW"}</span>)
                    </button>
                </h1>
                <button
                    id="infoButton"
                    onClick={() => {
                        dispatch({ type: "SHOW_INFO", payload: true });
                    }}
                >
                    INFO+CONTACT
                </button>
            </header>
        </>
    );
};
export default Header;
